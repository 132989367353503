import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { Unit } from "../types";
import { useNavigate, useParams } from "react-router-dom";

interface PaginateProps {
  units: Unit[];
  setUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
}

const token = process.env.REACT_APP_API_ACCESS_TOKEN;
const api_url = process.env.REACT_APP_API_URL;

const PaginateInput = ({ units, setUnits }: PaginateProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [floorInput, setFloorInput] = useState("");
  const [apartmentInput, setApartmentInput] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [inputError, setInputError] = useState(false);

  const navigate = useNavigate();
  const { buildingId } = useParams();
  const pageSize = 100;

  useEffect(() => {
    const fetchData = async (offset: number) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${api_url}/items/apartment?filter[building][_eq]=${buildingId}&page=${offset}&limit=${pageSize}`,
          config
        );

        setUnits(response.data.data);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    fetchData(currentPage);

    const fetchBuildingName = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${api_url}/items/building/${buildingId}`,
          config
        );

        setBuildingName(response.data.data.name);
      } catch (error) {
        console.error("Error fetching building name:", error);
      }
    };

    fetchBuildingName();
  }, [currentPage, buildingId, setUnits]);

  const handleCall = (type: string) => {
    let unit: Unit | undefined;

    if (type === "llamar") {
      unit = units.find(
        (unit) =>
          unit.floor.toString() === floorInput &&
          unit.apartment.toString() === apartmentInput
      );
    } else {
      unit = units.find((unit) => unit.name.toLowerCase() === type.toLowerCase());
    }

    if (!unit) {
      // console.error(`No unit found for the given inputs or type: ${type}`);
      // console.error(`Inputs - Floor: ${floorInput}, Apartment: ${apartmentInput}, Type: ${type}`);
      setInputError(true);
      return;
    }

    setInputError(false);
    navigate(`/selected/${unit.id}`);
    console.log(`Llamando a ${type}`);
  };

  return (
    <Box className="paginate-container" sx={{ textAlign: "center", padding: 3, backgroundColor: "#0067B2", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Typography variant="h5" sx={{ marginBottom: 2, color: "white", fontFamily: "Roboto-bold"}}>
        {buildingName}
      </Typography>
      <Box sx={{ marginBottom: 5, display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
        <Typography variant="h6" sx={{ marginBottom: 1, color: "white" }}>
          Marque Piso
        </Typography>
        <Box sx={{ backgroundColor: "white", borderRadius: 1, marginBottom: 2, width: "80%", maxWidth: "500px" }}>
          <TextField
            placeholder="000"
            variant="outlined"
            value={floorInput}
            onChange={(e) => setFloorInput(e.target.value)}
            inputProps={{ style: { textAlign: "center", fontSize: "1.5rem" } }}
            style={{ width: "100%", borderColor: inputError ? "#FF0000" : "inherit" }} 
            error={inputError} 
          />
        </Box>
        <Typography variant="h6" sx={{ marginBottom: 1, color: "white" }}>
          Marque Depto. / Oficina
        </Typography>
        <Box sx={{ backgroundColor: "white", borderRadius: 1, width: "80%", maxWidth: "500px", marginBottom: 2 }}>
          <TextField
            placeholder="000"
            variant="outlined"
            value={apartmentInput}
            onChange={(e) => setApartmentInput(e.target.value)}
            inputProps={{ style: { textAlign: "center", fontSize: "1.5rem" } }}
            style={{ width: "100%", borderColor: inputError ? "#FF0000" : "inherit" }} 
            error={inputError} 
          />
        </Box>
      
      <Button
        onClick={() => handleCall("llamar")}
        variant="contained"
        sx={{
          backgroundColor: "#39B54A",
          borderRadius: "20px",
          fontFamily: "Roboto",
          fontWeight: "bold",
          textTransform: "uppercase",
          padding: "10px 30px",
          marginBottom: 2,
          width: "80%",
          maxWidth: "500px",
        }}
      >
        Llamar
      </Button>
      <Button
        onClick={() => handleCall("porteria")}
        variant="contained"
        sx={{
          backgroundColor: "#00446D",
          borderRadius: "20px",
          fontFamily: "Roboto",
          fontWeight: "bold",
          textTransform: "uppercase",
          padding: "10px 30px",
          marginBottom: 2,
          width: "80%",
          maxWidth: "500px",
        }}
      >
        Portería
      </Button>
      <Button
        onClick={() => handleCall("paqueteria")}
        variant="contained"
        sx={{
          backgroundColor: "#00446D",
          borderRadius: "20px",
          fontFamily: "Roboto",
          fontWeight: "bold",
          textTransform: "uppercase",
          padding: "10px 30px",
          marginBottom: 2,
          width: "80%",
          maxWidth: "500px",
        }}
      >
        Paqueteria
      </Button>
      <Button
        onClick={() => handleCall("seguridad")}
        variant="contained"
        sx={{
          backgroundColor: "#00446D",
          borderRadius: "20px",
          fontFamily: "Roboto",
          fontWeight: "bold",
          textTransform: "uppercase",
          padding: "10px 30px",
          width: "80%",
          maxWidth: "500px",
        }}
      >
        Seguridad
      </Button>
    </Box>
    </Box>
  );
};

export default PaginateInput;
