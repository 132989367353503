import { Box, Button } from "@mui/material";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useEffect, useState } from "react";
import axios from "axios";
import { Unit } from "../types";
import { useParams } from "react-router-dom";

interface PaginateProps {
  units: Unit[]
  setUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
}

const token = process.env.REACT_APP_API_ACCESS_TOKEN
const api_url = process.env.REACT_APP_API_URL

const Paginate = ({ units, setUnits }: PaginateProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0)
  const pageSize = 6;
  const {buildingId} = useParams()

  const fetchData = async (offset:number) => {
    try {
      const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
      };
      
      const response = await axios.get(`${api_url}/items/apartment?filter[building][_eq]=${buildingId}&page=${offset}&limit=${pageSize}`, config);
      console.log(response.data);
      setUnits(response.data.data);
    } catch (error) {
      console.error('Error fetching units:', error);
    } finally {
    }
  };

  const fetchTotal = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.get(`${api_url}/items/apartment?filter[building][_eq]=${buildingId}`, config);
      setTotalPages(Math.ceil(response.data.data.length / pageSize));
    } catch (error) {
      console.error('Error fetching total pages:', error);
    }
  };

  useEffect(() => {
    fetchTotal();
    fetchData(currentPage);
  }, [currentPage, buildingId]);

  const navigateToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      fetchData(page);
    }
  };
  
  return (
    <Box sx={{ marginTop: 2, minWidth: '20%', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
      <Button variant="contained" onClick={() => navigateToPage(1)} sx={{ margin: '0 1px' }}><KeyboardDoubleArrowUpIcon /></Button>
      <Button variant="contained" onClick={() => navigateToPage(currentPage - 1)} sx={{ margin: '0 1px' }}><KeyboardArrowUpIcon /></Button>
      <Button variant="contained" sx={{ margin: '0 1px' }}>{`${currentPage} / ${totalPages}`}</Button>
      <Button variant="contained" onClick={() => navigateToPage(currentPage + 1)} sx={{ margin: '0 1px' }}><KeyboardArrowDownIcon /></Button>
      <Button variant="contained" onClick={() => navigateToPage(totalPages)} sx={{ margin: '0 1px' }}><KeyboardDoubleArrowDownIcon /></Button>
    </Box>
  );
};

export default Paginate;
