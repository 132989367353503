import './App.css'
import {Route, Routes} from "react-router-dom"
import LandingPanel from './Views/LandingPanel'
import ConfirmationView from './Views/ConfirmationView';

function App() {

  return (
    <div className='App'>
    <div className='wrap'>
      <Routes>
        <Route path="/:buildingId" element={<LandingPanel/>} />
        <Route path="/selected/:id" element={<ConfirmationView />} />
      </Routes>
    </div>
  </div>
  )
}

export default App;
