import axios from "axios";
import { useState } from "react";
import { Unit } from "../types";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";

interface CallingPanelProps {
  depto: Unit;
}

const token = process.env.REACT_APP_API_ACCESS_TOKEN;
const api_url = process.env.REACT_APP_API_URL;

const CallingPanel = ({ depto }: CallingPanelProps) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState<{ success: string; error: string }>({
    success: "",
    error: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [callSuccess, setCallSucess] = useState<boolean>(false);

  const { destination, name, floor, apartment, building, confirmation_text } =
    depto;

  const handleCalling = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const buildingResponse = await axios.get(
        `${api_url}/items/building/${building}`,
        config
      );
      const { name, server, key, door_phone } = buildingResponse.data.data;

      const callResponse = await axios.get(`${api_url}/call`, {
        params: {
          sensor_name: name,
          key,
          portero: door_phone,
          destino: destination,
          server,
          confirmation_text,
        },
      });
      setMessages({ success: "Successful call", error: "" });
      setCallSucess(true);
    } catch (error) {
      console.error("Error when making the call:", error);
      setMessages({ success: "", error: "Call in progress." });
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: 5,
        backgroundColor: "#ffffff",
        borderRadius: 3,
        color: "#2196f3",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!callSuccess && (
        <Box mb={3}>
          <Typography
            variant="body1"
            sx={{ fontSize: "2rem", fontWeight: 500, wordBreak: "break-word" }}
          >
            {confirmation_text}
          </Typography>
        </Box>
      )}
      <Grid container direction="row" justifyContent="center" spacing={2} marginBottom={2}>
        <Grid item>
          {!callSuccess && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCalling}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Call"
              )}
            </Button>
          )}
          {callSuccess && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <QrCodeIcon sx={{ fontSize: 100, color: "black" }} />
              <Typography variant="body2" sx={{ color: "gray", marginTop: 1 }}>
                ¡Gracias por utilizar Intercomqr!
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item>
          {!callSuccess && (
            <Button
              variant="contained"
              color="inherit"
              onClick={() => navigate(-1)}
              disabled={loading}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Grid>
      {/* {messages.success && (
        <Typography variant="body2" sx={{ marginTop: 2, color: 'green' }}>
          {messages.success}
        </Typography>
      )} */}
      {messages.error && (
        <Typography variant="body2" sx={{ marginTop: 2, color: "green" }}>
          {messages.error}
        </Typography>
      )}
    </Box>
  );
};

export default CallingPanel;

