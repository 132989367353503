import axios from "axios"
import { Box, CircularProgress } from "@mui/material"
import { useParams } from "react-router-dom"
import CallingPanel from "../Components/CallingPanel"
import { useEffect, useState } from "react"
import { Unit } from "../types"

const token = process.env.REACT_APP_API_ACCESS_TOKEN
const api_url = process.env.REACT_APP_API_URL

const ConfirmationView = () => {

  const params = useParams()
  const id = params.id
  const [loading, setLoading] = useState<boolean>(false)
  const [unit, setUnit] = useState<Unit>({
    id: null,
    name: '',
    destination: '',
    apartment: '',
    building: null,
    date_created: '',
    date_updated: '',
    floor: '',
    door_phone: '',
    user_created: '',
    user_updated: '',
    web_layout: '',
    confirmation_text: ''
  })

  useEffect(() => {
    const fetchUnit = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
        const response = await axios.get(`${api_url}/items/apartment/${id}`, config);
        setUnit(response.data.data)
      } catch (error) {
        console.error('Error fetching units:', error);
      }
    };
    
    fetchUnit();
  }, [id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#0067B2",
        color: "#ffffff",
      }}
    >
      {loading ? (
        <CircularProgress />
      ):(
        <CallingPanel depto={unit}  />
      )}
    </Box>
  )
}

export default ConfirmationView
