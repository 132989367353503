import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { Unit } from "../types";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import UnitLine from "./UnitLine";

interface PaginateProps {
  units: Unit[];
  setUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
}

const token = process.env.REACT_APP_API_ACCESS_TOKEN;
const api_url = process.env.REACT_APP_API_URL;

const sortFunction = (a: string, b: string) => {
  const aNum = parseInt(a, 10);
  const bNum = parseInt(b, 10);

  if (!isNaN(aNum) && !isNaN(bNum)) {
    return aNum - bNum;
  }

  return a.localeCompare(b);
}

const PaginateNew = ({ units, setUnits }: PaginateProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { buildingId } = useParams();
  const pageSize = 100;

  useEffect(() => {
    const fetchData = async (offset: number) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${api_url}/items/apartment?filter[building][_eq]=${buildingId}&sort=apartment&page=${offset}&limit=${pageSize}`,
          config
        );

        setUnits(response.data.data.sort((a: Unit, b: Unit) => sortFunction(a.apartment,b.apartment)).sort((a: Unit, b: Unit) => sortFunction(a.floor,b.floor)))

      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    fetchData(currentPage);
  }, [currentPage, buildingId, setUnits]);


  const groupedUnits = units.reduce((acc, unit) => {
    (acc[unit.name] = acc[unit.name] || []).push(unit);
    return acc;
  }, {} as Record<string, Unit[]>);

  return (
    <Box className="paginate-container">
      <div className="items-list">
        {Object.entries(groupedUnits).map(([floor, unitsOnFloor], index) => (
          <div key={index}>
            <Box
              className="floor-group"
              sx={{
                fontFamily: "Roboto",
                display: "flex",
                gap: 1,
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {floor}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {(unitsOnFloor as Unit[]).map((unit, idx) => (
                  <Button
                    key={idx}
                    onClick={() => navigate(`/selected/${unit.id}`)}
                    variant="contained"
                    sx={{
                      backgroundColor: "#004287",
                      borderRadius: "5px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      padding: "10px 30px",
                    }}
                  >
                    <Typography variant="body1">{unit.apartment}</Typography>
                  </Button>
                ))}
              </Box>
            </Box>
            <hr key={index} color="#004287" />
          </div>
        ))}
      </div>
    </Box>
  );
};
export default PaginateNew;
