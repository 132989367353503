import { Box } from "@mui/material";
import { Unit } from "../types";
import UnitLine from "./UnitLine";
import Paginate from "./Paginate";
import PaginateNew from "./PaginateNew";
import PaginateInput from "./PaginateInput";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface UnitsBoardProps {
  units: Unit[];
  setUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
}

const token = process.env.REACT_APP_API_ACCESS_TOKEN;
const api_url = process.env.REACT_APP_API_URL;

const UnitsBoard = ({ units, setUnits }: UnitsBoardProps) => {
  const params = useParams();
  const [webLayout, setWebLayout] = useState<string>("");
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchBuilding = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const buildingId = params.buildingId;

        const response = await axios.get(
          `${api_url}/items/building/${buildingId}`,
          config
        );
        setWebLayout(response.data.data.web_layout);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };
    fetchBuilding().then(() => setLoading(false));
  }, [setWebLayout, params.buildingId]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {webLayout === "lista" ? (
        <Box
          sx={{
            padding: 2,
            borderRadius: 3,
            backgroundColor: "#ffffff",
            color: "#2196f3",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "90%",
              padding: 2,
              backgroundColor: "#0067B2",
              borderRadius: 3,
            }}
          >
            {units.map((dpto, index) => (
              <UnitLine key={index} unit={dpto} index={index} maxLength={20} />
            ))}
          </Box>
          <Paginate units={units} setUnits={setUnits} />
        </Box>
      ) : webLayout === "generico" ? (
        <PaginateNew units={units} setUnits={setUnits} />
      ) : (
        <PaginateInput units={units} setUnits={setUnits} />
      )}
    </>
  );
};

export default UnitsBoard;
