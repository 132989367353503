import { useNavigate } from "react-router-dom";
import { ListItem, ListItemButton, Typography } from "@mui/material";
import { Unit } from "../types";

interface UnitLineProps {
  unit: Unit;
  index: number;
  maxLength: number;
}

const UnitLine = ({ unit, index, maxLength }: UnitLineProps) => {
  const navigate = useNavigate();

  const truncateText = (text:string) => {
    // maxLength is a prop that is passed to the component
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  //Flex-end para demo en hebreo
  return (
    <ListItem disablePadding key={index}>
      <ListItemButton onClick={() => navigate(`/selected/${unit.id}`)} sx={{ justifyContent: "center" }} >
        <Typography variant="body1" sx={{ color: "white", fontSize: "1.5rem", fontWeight: 500}}>
          {truncateText(unit.name)}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};

export default UnitLine;

