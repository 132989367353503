import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import UnitsBoard from "../Components/UnitsBoard"
import { Unit } from "../types"

const LandingPanel = () => {
  const [units, setUnits] = useState<Unit[]>([])

  useEffect(() => {
  }, [units])

  return (
    // Change the background color of the Box component to #0067B2
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#0067B2",
        color: "#ffffff",
      }}
    >
      <UnitsBoard units={units} setUnits={setUnits}/>
    </Box>
  )
}

export default LandingPanel;
